<template>
  <div class="wrapper">
    <div class="page">
      <div id="main-loading-cover">
        <div class="page-inner" id="main_page">
          <div class="va-card">
            <div class="dash_card_body">
              <div class="header_section">
                <h4 class="menu_header">Merchants Analytics</h4>
                <div class="" v-if="user.role.id == 1 || user.role.id == 2">
                  <div class="btn-toolbar">
                    <button class="btn btn-default custom_btn" @click="exportReport()">
                      <span v-if="exportLoader"><i class="fa fa-spinner fa-spin"></i></span> <span v-else><i class="fas fa-file-export"></i> </span> Excel
                    </button>
                  </div>
                </div>
              </div>
  
              <div class="search_section">
                <div class="row">
                  <div class="col-md-10 col-sm-12">
                    <div class="form-group mt-1">
                      <select v-model="report" @change="filterReport()" class="form-control">
                        <option value="">--Select Report --</option>
                        <option value="all">Analytics</option>
                        <option value="salesByDate">Sales By Date</option>
                        <option value="salesByHour">Sales By Hour</option>
                        <option value="salesByAgent">Sales By Agent</option>
                        <option value="salesByProduct">Sales By Product</option>
                        <option value="salesByStore">Sales By Store</option>
                        <option value="salesByPaymentOption">Sales By Payment Option</option>
                        <option value="salesByChannel"> Sales By Channel</option>
                        <option value="salesByCategory">Sales By Category</option>
                        <option value="toplocations">Top Locations</option>
                        <option value="topcustomers">Top Customers</option>
                        <option value="offers">Offers Report</option>
                        <option value="merchants">Merchants Report</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-12">
                    <button class="btn btn_reserveport_orange mt-1" @click="filterReport()"><span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span> search</button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2">
                    <Datepicker placeholder="Start Date" v-model="startDate" utc autoApply :format="customFormatter"></Datepicker>
                  </div>
                  <div class="col-md-2">
                    <Datepicker placeholder="End Date" v-model="endDate" utc autoApply :format="customFormatter"></Datepicker>
                  </div>
                  <div class="col-md-2">
                    <select class="form-control" v-model="searchChannel" >
                      <option value="">--Select Channel--</option>
                      <option value="FOODIE">FOODIE</option>
                      <option v-for="channel in channels" :key="channel.id" :value="channel.channel_name">
                        {{channel.channel_name}}</option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <select class="form-control" v-model="merchantid" >
                      <option value="">--Select Merchant--</option>
                      <option v-for="merchant in merchants" :key="merchant.id" :value="merchant.id">
                        {{merchant.name}}</option>
                    </select>
                  </div>
                
                  
                </div>
              </div>
  
            </div>
            <div class="dash_card_body">
              <div class="summary_body">
                <!-- orders Report -->
               
    
                <!-- merchants Report -->
                <div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="pl-3r pr-2r pbt-1r bd-b">
                        <div class="content_wrapper_box  graph-summary">
                          <div class="wrapper_header">
                            <div class="header_section wrapper_search">
                              <!-- <div class="row">
                                <div class="col-md-3">
                                  <Multiselect class="dash_summary_form" @click="filterSalesSummary()"  v-model="location" :close-on-select="true" :options="strsoptions" :custom-label="name" :searchable="true" :placeholder="'Stores ('+strsoptions.length +')'" label="name" track-by="name"></Multiselect>
                                </div>
                                <div class="col-md-5">
                                  <Datepicker  class="dash_summary_form"  v-model="dateRange" range :presetRanges="presetRanges" utc autoApply  :format="RangeCustomFormatter" @blur="filterSalesSummary()">
                                    <template #yearly="{ label, range, presetDateRange }">
                                      <span @click="presetDateRange(range)">{{ label }}</span>
                                    </template>
                                  </Datepicker>

                                </div>
                              </div> -->
                            </div>
                            <div class="header_section wrapper_search">
                              <div>
                                <span class="dash_header_text">{{summaryTitle}}</span>
                              </div>
                              <div>
                                <button :class="'btn btn-sm mr-2 '+ salesBtn" @click="getMerchantSaleSummary('sales')">Sales</button>
                                <button :class="'btn btn-sm ' + ordersBtn" @click="getMerchantSaleSummary('orders')">Orders</button>
                              </div>
                            </div>
                           
                          </div>
                          <div id="chart-container" class="row">
                            <div class="col-md-12" id="summary-chart">
                              <GChart type="BarChart" :data="ChartData" :options="ChartOptions" />
                            </div>
                  
                            <!-- <canvas id="merchant-summary-chart" class="col-12"></canvas> -->
                          </div>
                        </div> <!--Graph-->
                      </div><!-- End Graph-->
                    </div>
                   
                    <div class="col-md-12">
                      <div class="cardbox card h-100">
                        <div class="section_content pad-lr-0 pop">
                          <div class="header_section wrapper_search">
                            <div class="dash_header_text">
                              <h2>Merchant Sales</h2>
                            </div>
                        
                          </div>
                          <div class="">
                            <div :class="['table_section', {'disabled_content': summaryLoading}]">
                              <table class="table table-hover">
                                <thead>
                                  <th>Merchant Name</th>
                                  <th>Orders</th>
                                  <th>Previous Orders <br> ({{ previousPeriodTime }})</th>
                                 
                                  <th>Sales (<b>{{ pesapalConfig.currency_code ? pesapalConfig.currency_code :'KSH' }}</b>)</th>
                                  <th>Previous Sales <br> ({{previousPeriodTime}})</th>
                                  <th>Change</th>
                                </thead>
  
                                <tbody v-if="!loading && summarySales.length > 0">
                                  <tr v-for="summary in summarySales" :key="summary.merchant_name">
                                    <td>{{summary.merchant_name}}</td>
                                    <td>{{summary.total_order}}</td>
                                    <td>{{ summary.order_previous }}</td>
                                    
                                    <td>{{formatPrice(summary.total_amount)}}</td>
                                    <td>{{ formatPrice(summary.sales_previous) }}</td>
                                    <td>
                                      <span v-if="summary.change !='nochange'" :class="[{'increase_change':summary.change == 'increase'},{'decrease_change':summary.change == 'decrease'}]">
                                        <i :class="[{'fa fa-arrow-up':summary.change == 'increase'},{'fa fa-arrow-down': summary.change == 'decrease'}]" style="font-size:12px"></i> &nbsp; {{summary.percentange}}%
                                      </span>
                                      <span v-else>N/A</span>
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr>
                                    <td style="text-align:center;" :colspan="(report =='topcustomers' && $report =='toplocations' && report != 'salesByHour' && report != 'salesByDate') ? '4': '5'">No Data</td>
                                  </tr>
                                </tbody>
                                <tfoot v-if="!loading">
                                  <tr>
                                    <td colspan="8">
                                      <div id="pagination" v-if="summarytotalPages > 1">
                                        <Pagination :total-pages="summarytotalPages" :per-page="summaryrecordsPerPage" :current-page="summarypage" @pagechanged="onSummaryPageChange" />
                                        <ul class="showItems">
                                          <li>Show Items:
                                            <select @change="onChangeSummaryRecordsPerPage" v-model="summaryrecordsPerPage">
                                              <option :value="10">10</option>
                                              <option :value="20">20</option>
                                              <option :value="30">30</option>
                                              <option :value="40">40</option>
                                              <option :value="50">50</option>
                                            </select>
                                          </li>
  
                                        </ul>
                                      </div>
  
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="cardbox card h-100">
                        <div class="section_content pad-lr-0 pop">
                          <div class="header_section wrapper_search">
                            <div class="dash_header_text">
                              <h2>Merchant Channel Sales</h2>
                            </div>
                        
                          </div>
                          <div class="">
                            <div :class="['table_section', {'disabled_content': channeLoading}]">
                              <table class="table table-hover">
                                <thead>
                                  <th>Merchant</th>
                                  <th>Channel</th>
                                  <th>Orders</th>
                                  <th>Previous Orders <br>
                                    ({{ previousPeriodTime }})</th>
                                  <th>Sales (<b>{{ pesapalConfig.currency_code ? pesapalConfig.currency_code :'KSH' }}</b>)</th>
                                  <th>
                                    Previous Sales <br>
                                    <span>({{ previousPeriodTime }})</span>
                                  </th>
                                  <th>Change</th>
                                </thead>
  
                                <tbody v-if="!loading && channelSales.length > 0">
                                  <tr v-for="channelsale in channelSales" :key="channelsale.merchant_name">
                                    <td>{{channelsale.merchant_name}}</td>
                                    <td>{{ channelsale.channel }}</td>
                                    <td>{{channelsale.total_order}}</td>
                                    <td>
                                      {{ channelsale.order_previous }}
                                    </td>
                                    <td>{{formatPrice(channelsale.total_amount)}}</td>
                                    <td>
                                      {{ channelsale.sales_previous }}
                                    </td>
                                    <td>
                                      <span v-if="channelsale.change !='nochange'" :class="[{'increase_change':channelsale.change == 'increase'},{'decrease_change':channelsale.change == 'decrease'}]">
                                        <i :class="[{'fa fa-arrow-up':channelsale.change == 'increase'},{'fa fa-arrow-down': channelsale.change == 'decrease'}]" style="font-size:12px"></i> &nbsp; {{channelsale.percentange}}%
                                      </span>
                                      <span v-else>N/A</span>
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr>
                                    <td style="text-align:center;" colspan="4">No Data</td>
                                  </tr>
                                </tbody>
                                <tfoot v-if="!loading">
                                  <tr>
                                    <td colspan="8">
                                      <div id="pagination" v-if="channelTotalPages > 1">
                                        <Pagination :total-pages="channelTotalPages" :per-page="channelrecordsPerPage" :current-page="channelSalesPage" @pagechanged="channelSalePageChange" />
                                        <ul class="showItems">
                                          <li>Show Items:
                                            <select @change="onChangeChannelSaleRecordsPerPage" v-model="channelrecordsPerPage">
                                              <option :value="10">10</option>
                                              <option :value="20">20</option>
                                              <option :value="30">30</option>
                                              <option :value="40">40</option>
                                              <option :value="50">50</option>
                                            </select>
                                          </li>
  
                                        </ul>
                                      </div>
  
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                    
                     
                </div>
  
  
  
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="main-loader"></div>
    </div>
  </div>
</template>
  
  <script>
  import moment from 'moment';
  import ReusableDataMixins from '@/mixins/ReusableDataMixins';
  import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
  import Pagination from '@/pages/pagination/pagination.vue';
  import { GChart } from 'vue-google-charts';
  
  export default {
      components: {
          Pagination,
          GChart
      },
      data() {
          return {
              searchChannel: '',
              channels: [],
              spinner: false,
              loading: true,
              startDate: this.$store.state.ordReportstartDate,
              endDate: this.$store.state.ordReportendDate,
              location: this.$store.state.ordReportlocation,
              limit: 10,
              report: '',

             
              summarypage: 1,
              summarytotalPages: 0,              
              summarytotalRecords: 0,
              summaryrecordsPerPage: 10,

              channelSalesPage:1,
              channelTotalPages: 0,
              channeltotalRecords:0,
              channelrecordsPerPage:10,


              channelSales:[],
              summarySales:[],
              merchant: '',
              summaryLoading:false,
              channeLoading:false,
              merchantid: '',
              merchants:[],
              exportLoader:false,
              totalOrderSales: 0,
              currentPeriodTime:'',
              totalPreviousOrderSales:0,
              previousPeriodTime:'',
              summaryTitle:"Sales",
              merchantSalesChartData: [
                ['Merchants', 'Sales']
              ],
              merchantOrdersChartData: [
                ['Merchants', 'Orders']
              ],
              salesType: 'sales',
              ChartOptions: {
                  height: 800,
              },
              salesBtn: 'custon_orange',
              ordersBtn: 'btn-merchant',

          }
      },
      mounted: async function () {
          this.getChannels();
          this.getMerchantsReport('',1,10);
          this.getMerchants();
          this.getMerchantSaleSummary();
      },
      methods: {
          customFormatter(date) {
              return moment(date).format('YYYY-MM-DD');
          },
          filterReport() {
              if (this.report != '') {
                if(this.report =='merchants'){
                  this.$router.push({
                    path:"merchants-report"
                  })
                } else if(this.report  == 'all'){
                    this.$router.push({
                        path:"analytics"
                    })

                } else {
                  this.$router.push({
                      name: 'dashboard_details',
                      params: {
                          type: this.report
                      }
                  });
                }
                  
              } 
              this.getMerchantsReport('',1,10);
              this.merchantSalesChartData = [
                ['Merchants', 'Sales']
              ],
              this.merchantOrdersChartData = [
                ['Merchants', 'Orders']
              ],
              this.getMerchantSaleSummary();
          },
          async getMerchantSaleSummary(type ='sales'){
            this.salesType = type
            this.merchantSalesChartData = [
              ['Merchants', 'Sales']
            ]
            this.merchantOrdersChartData = [
              ['Merchants', 'Orders']
            ]
            try {
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              let url = `${this.dashBaseUrl}/merchant-orders-report`;
              url = new URL(url);
              if (this.startDate !== '') {
                  url.searchParams.set('start_date', this.startDate);
              }
              if (this.endDate !== '') {
                  url.searchParams.set('end_date', this.endDate);
              }
              if(this.merchantid !==''){
                url.searchParams.set('merchant_id',this.merchantid)
              }
              if(this.searchChannel !==''){
                url.searchParams.set('channel',this.searchChannel);
              }
              const response = await this.axios.get(url, config);
              if(response.status == 200){
                const summaries = response.data.summary
                this.previousPeriodTime = `${moment(summaries[0].pstart_date).format('LL')} to ${ moment( summaries[0].pend_date).format('LL')}`
                for (const summary of Object.values(summaries)) {
                    if(type == 'sales'){
                      this.summaryTitle = 'Sales'
                      this.salesBtn = ' custon_orange';
                      this.ordersBtn = ' btn-merchant';
                      const merchantSummaryArray = [summary.merchant_name, summary.total_amount]
                      this.merchantSalesChartData.push(merchantSummaryArray)
                      this.ChartData =  this.merchantSalesChartData
                    }else{
                      this.summaryTitle = "Orders"
                      this.salesBtn =  ' btn-merchant';
                      this.ordersBtn = ' custon_orange';
                      const merchantOrderSummaryArray = [summary.merchant_name, summary.total_order]
                      this.merchantOrdersChartData.push(merchantOrderSummaryArray)
                      this.ChartData =  this.merchantOrdersChartData
                    }
                      
                }
              }
            } catch (error) { 
              this.merchantSalesChartData = [
                ['Merchants', 'Sales']
              ]
              this.merchantOrdersChartData = [
                ['Merchants', 'Orders']
              ]
            }

          },
          async getMerchantsReport(reportType,page,size){
            this.loading = true
            this.summaryLoading = reportType == 'summary' ? true : false
            this.channeLoading = reportType == 'channel' ? true : false
            const LoaderCover = document.getElementById('main-loading-cover');
            LoaderCover.style.opacity = .4;
            const loader = document.getElementById('main-loader');
            loader.style.display = 'block';
            const payload = {
                'start_date': this.startDate,
                'end_date': this.endDate,
            }
            this.$store.commit('updateOReportSearchData', payload)
            try{
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              let url = `${this.dashBaseUrl}/merchant-orders-report`;
              url = new URL(url);
              url.searchParams.set('page', page);
              url.searchParams.set('size', size);
            
              if (this.startDate !== '') {
                  url.searchParams.set('start_date', this.startDate);
              }
              if (this.endDate !== '') {
                  url.searchParams.set('end_date', this.endDate);
              }
              if(this.merchantid !==''){
                url.searchParams.set('merchant_id',this.merchantid)
              }
              if(this.searchChannel !==''){
                url.searchParams.set('channel',this.searchChannel);
              }
              const response = await this.axios.get(url, config);
              if(response.status == 200){
                this.loading = false
                LoaderCover.style.opacity = 1;
                loader.style.display = 'none';
          
                if(reportType == 'summary'){
                  this.summarySales = response.data.summary.data
                  this.summarytotalPages = Math.ceil(response.data.summary.total / this.summaryrecordsPerPage)
                } else if(reportType == 'channel'){
                  this.channelSales = response.data.channelsales.data
                  this.channelTotalPages = Math.ceil(response.data.channelsales.total / this.channelrecordsPerPage)
                }else{
                  this.summarySales = response.data.summary.data
                  this.summarytotalPages = Math.ceil(response.data.summary.total / this.summaryrecordsPerPage)

                  this.channelSales = response.data.channelsales.data
                  this.channelTotalPages = Math.ceil(response.data.channelsales.total / this.channelrecordsPerPage)
                }
                this.summaryLoading = false
                this.channeLoading = false

                // summary graph




              }   
              // 
            }catch(error){
                this.loading = false
                this.summaryLoading = false
                this.channeLoading = false
              LoaderCover.style.opacity = 1;
              loader.style.display = 'none';
            }
          },
          async getMerchants() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/merchants`;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.merchants = response.data.data;
                }
            }).catch((err) => {
                console.log('error', err);
            })

        },
          async getChannels() {
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              const url = this.baseUrl + "/all-third-party-settings";
              this.axios.get(url, config).then((response) => {
                  if (response.status === 200) {
                      this.channels = response.data.data
                  }
              });
          },
          async exportReport(){
             
            this.exportLoader = true
            try{
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              let url = `${this.dashBaseUrl}/merchant-orders-report`;
              url = new URL(url);
              if (this.startDate !== '') {
                  url.searchParams.set('start_date', this.startDate);
              }
              if (this.endDate !== '') {
                  url.searchParams.set('end_date', this.endDate);
              }
              if(this.merchantid !==''){
                url.searchParams.set('merchant_id',this.merchantid)
              }
              if(this.searchChannel !==''){
                url.searchParams.set('channel',this.searchChannel);
              }
              const response = await this.axios.get(url, config);
              if(response.status == 200){
                this.exportLoader = false;
                const channelSales = response.data.channelsales;
                const summarySales = response.data.summary;
                const channelSalesData = [];
                const summarySalesData = [];
                for(let ch =0; ch < channelSales.length; ch++ ){
                    channelSalesData.push({
                        'Merchant name': channelSales[ch].merchant_name,
                        'Channel': channelSales[ch].channel,
                        'Orders': channelSales[ch].total_order,
                        'Sales': this.formatPrice(channelSales[ch].total_amount)
                    })
                }
                for(let sm=0; sm < summarySales.length; sm++ ){
                    summarySalesData.push({
                        'Merchant Name':summarySales[sm].merchant_name,
                        'Orders': summarySales[sm].total_order,
                        'Sales': this.formatPrice(summarySales[sm].total_amount)
                    })
                }

                const filename = Date.now()+'_merchant_orders_report'
                this.downLoadMerchantRepotExcel(filename,channelSalesData,summarySalesData)
              }   
              // 
            }catch(error){
             this.exportLoader = false   
            }

          },
          onChangeSummaryRecordsPerPage(){
            this.getMerchantsReport('summary',this.summarypage,this.summaryrecordsPerPage)
          },
          onSummaryPageChange(page){
            this.summarypage = page
            this.getMerchantsReport('summary',this.summarypage,this.summaryrecordsPerPage)
          },
          onChangeChannelSaleRecordsPerPage() {
            this.getMerchantsReport('channel',this.channelSalesPage,this.channelrecordsPerPage)
          },
          channelSalePageChange(page){
            this.channelSalesPage = page
            this.getMerchantsReport('channel',this.channelSalesPage,this.channelrecordsPerPage)
          }
      },
      mixins: [ReusableDataMixins, ReusableFunctionMixins]
  }
  </script>
  